import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'intl/messages.properties';
import { Link } from 'react-router-dom';

import Logout from './Logout';
import home from '../../path';

import {
  AppBar,
  CssBaseline,
  Drawer,
  Hidden,
  IconButton,
  Button,
  Toolbar,
  Typography,
  Box
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import SiteMap from '../../Home/SiteMap';
import {useUser} from "@postinumero/authorization/index";
import { LinkContainer } from 'react-router-bootstrap';
import settings from '../../Settings/path'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faCog, faUsers } from "@fortawesome/free-solid-svg-icons/index";

import logo from '../../../images/serlachius_logo.en_vaintalot.jpeg';
import appBarStyle from '../../../styles/AppBarStyle';
import Authorize from "@postinumero/authorization/Authorize";

const useStyles = makeStyles(appBarStyle);

function ResponsiveDrawer() {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const user = useUser();

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  return (
    <Box display="block" displayPrint="none">
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}>
            <FontAwesomeIcon icon={faBars}
                             style={{marginRight: 10}} />
          </IconButton>


          <Typography variant="h6" noWrap to={home}>
            <LinkContainer to={home}>
              <FormattedMessage id={messages.title} />
            </LinkContainer>
          </Typography>


          <div style={{display: "flex", position: "absolute", right: "20px"}}>
          {user ? (<>

            <Authorize allow="Myyntipalvelu">
              <Link to={settings}>
                <Button style={{ height: "100%", padding: "5px 10px", marginRight: 10 }} onClick={() => window.location = process.env.KEYCLOAK_ADMIN}>
                  <FontAwesomeIcon icon={faUsers}
                                   color={"#FFF"}
                                   style={{ height: "1.5em", width: "1.5em" }} />
                </Button>
              </Link>
            </Authorize>
            <Authorize allow="Myyntipalvelu">
              <Link to={settings}>
                <Button style={{ height: "100%", padding: "5px 10px", marginRight: 10 }}>
                  <FontAwesomeIcon icon={faCog}
                                 color={"#FFF"}
                                 style={{ height: "1.5em", width: "1.5em" }} />
                </Button>
              </Link>
            </Authorize>
            <Logout style={{
              height: "100%",
              padding: "5px 10px"
            }}/>
          </>
          ) : (
            ""
          )}
          </div>
        </Toolbar>

      </AppBar>

      { user ? <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <SiteMap className={classes.menulist}/>
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <SiteMap/>
            <a href="http://www.serlachius.fi/fi/">
              <img src={logo}
                   className={classes.image}
                   alt="logo" />
            </a>
          </Drawer>
        </Hidden>
      </nav>
        : '' }
    </div>
    </Box>
  );
}

export default ResponsiveDrawer;
