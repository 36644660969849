import React, {useEffect} from 'react';
import {FormattedMessage} from 'react-intl';
import messages from 'intl/messages.properties';
import {isEmpty} from 'lodash';
import {camelCase} from 'lodash';
import ColorPicker from 'material-ui-color-picker'

import reservationTypes from "../../../../shared/definitions/reservationTypes";
import languageList from "../../../../shared/definitions/languageList";

import { Button,
  FormControl,
  Box,
  TextField,
  Select,
  Checkbox,
  MenuItem } from '@material-ui/core';
import {KeyboardDatePicker, KeyboardTimePicker} from '@material-ui/pickers';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faMinus, faTimes} from "@fortawesome/free-solid-svg-icons/index";
import {useFormContext} from "react-hook-form";
import { Authorize } from "@postinumero/authorization";
import useAuthorize from "@postinumero/authorization/useAuthorize";
import {Alert} from "react-bootstrap";
import useAxios from "use-axios/cjs";

const moment = require('moment');

export default function BasicInfo(props) {
  const {classes, removeProgramItemsByDate, moveProgramItemsToDate, reservationData} = props;
  const [show, setShow] = React.useState(false);
  const [basicInfoErrors, setBasicInfoErrors] = React.useState({});

  const isAsiakaspalvelu = useAuthorize({ allow: "Asiakaspalvelu" })

  const { register, watch, setValue, errors } = useFormContext();

  useEffect(() => {
    register({ name: "reservationVisitingTimes" });
    register({ name: "reservationGroups" });
    register({ name: "reservationType" }, { required: messages.reservationTypeRequired });
    register({ name: "reservationSize" }, { required: messages.reservationSizeRequired });
  }, [register]);

  const visitingTimesWatch = watch("reservationVisitingTimes");
  const groupsWatch = watch("reservationGroups");
  const typeWatch = watch("reservationType");
  const sizeWatch = watch("reservationSize");

  function handleNameChange(event) {
    const newName = event.target.value;
    setValue("reservationName", newName);
  }

  const handleItemDateChange = (index, oldDate) => date => {
    const newDate = moment(date).format("YYYY-MM-DD");
    if (visitingTimesWatch.find(vt => newDate === vt.visitingDate)) {
      return;
    }
    const visitingTime = moment(date);
    const newVisitingTimes = Object.assign([], visitingTimesWatch);
    newVisitingTimes[index].visitingDate = visitingTime.format("YYYY-MM-DD");
    /*visitingTimesWatch.sort(function(a, b) {
      return new Date(a.visitingDate) - new Date(b.visitingDate);
    });*/
    const success = moveProgramItemsToDate(oldDate, visitingTime.format("YYYY-MM-DD"));
    if (success) {
      setValue("reservationVisitingTimes", newVisitingTimes);
      const newErrors = basicInfoErrors;
      delete newErrors.visitingTime;
      setBasicInfoErrors(newErrors)
    } else {
      visitingTimesWatch.forEach(vTime => vTime.visitingDate = vTime.visitingDate === moment(date).format("YYYY-MM-DD") ? oldDate : vTime.visitingDate);
      setValue("reservationVisitingTimes", visitingTimesWatch)
      setBasicInfoErrors({visitingTime: { message: messages.dateProductsLimited }})
    }
  };

  const handleTimeChange = (timeField, index) => time => {
    const val = moment(time);
    const newVisitingTimes = [...visitingTimesWatch];
    newVisitingTimes[index][timeField] = val.format("HH:mm");
    setValue("reservationVisitingTimes", newVisitingTimes);
  };

  function handleSelectChange(event) {
    setValue(event.target.name, event.target.value);
  }

  function handleVisitingTimeAdd() {
    const latestVisitingTime = visitingTimesWatch.reduce((prev, current) => moment(prev.visitingDate).isAfter(moment(current.visitingDate)) ? prev : current);
    const newTime = { visitingDate: moment(latestVisitingTime.visitingDate).add(1, 'days').format("YYYY-MM-DD"), startingTime: '08:00', endingTime: '20:00' };
    const newTimes = [...visitingTimesWatch];
    newTimes.push(newTime);
    setValue("reservationVisitingTimes", newTimes);
  }

  function handleVisitingTimeRemove(visitingTimeIndex) {
    const newVisitingTimes = [...visitingTimesWatch.filter( (group, index) => index !== visitingTimeIndex)];
    const removedDate = visitingTimesWatch[visitingTimeIndex];
    setValue("reservationVisitingTimes", newVisitingTimes);
    removeProgramItemsByDate(removedDate, newVisitingTimes);
  }

  function handleGroupCheckChange( group, language, groupIndex ) {
    const newGroups = [...groupsWatch];

    newGroups[groupIndex].languages.includes(language)
      ? newGroups[groupIndex].languages = newGroups[groupIndex].languages.filter( groupLanguage => groupLanguage !== language)
      : newGroups[groupIndex].languages.push(language)
    setValue("reservationGroups", newGroups);
  }

  function handleColorChange(group, color, groupIndex) {
    const newGroups = [...groupsWatch];
    newGroups[groupIndex].groupColorHex = color;
    setValue("reservationGroups", newGroups);
  }

  function handleSizeChange(event) {
    const newSize = event.target.value;
    setValue("reservationSize", newSize);
  }

  function handleGroupSizeChange(group, event, groupIndex) {
    const newGroups = [...groupsWatch];
    newGroups[groupIndex].groupSize = event.target.value;
    setValue("reservationGroups",  newGroups);
  }

  function handleGroupAdd() {
    const emptyGroup = { tempId: Date.now(),groupSize: '0', groupColorHex: '#3e3e3d', languages: [], reservationGroupProgramItems: [] } ;
    const newGroups = [...groupsWatch];
    newGroups.push(emptyGroup);
    setValue("reservationGroups", newGroups);
  }

  function handleGroupRemove(groupIndex) {
    const newGroups = [...groupsWatch.filter( (group, index) => index !== groupIndex)];
    setValue("reservationGroups", newGroups);
  }

  function DefaultReservationName() {
    const refetchedreservationName = useAxios(`${process.env.API_PATH}/reservation/${reservationData.id}`);
    return (refetchedreservationName.data.reservationName);
  }

  return (<Box>

      {!isEmpty(basicInfoErrors) && Object.values(basicInfoErrors).map((error, index) => {
          return <Alert key={index} variant="danger">
            <FormattedMessage id={error.message}/>
          </Alert>
        }
      )}
      <FormControl className={classes.formControl}>
        <label className={classes.label}>
          <FormattedMessage id={messages.reservationName}/>
           *
        </label>
        <TextField id="reservationName"
                   name="reservationName"
                   defaultValue={reservationData.id ? DefaultReservationName() : ""}
                   inputProps={{
                     name: 'reservationName',
                     id: 'reservationName'
                   }}
                   onChange={handleNameChange}
                   disabled={!useAuthorize({ allow: "Asiakaspalvelu" })}
                   inputRef={register({ required: messages.reservationNameRequired })}
                   error={errors.reservationName}
                   className={classes.textField}
                   variant="filled"/>
      </FormControl>
      {visitingTimesWatch.map((visitingTime, index) => {

          // TimePicker requires a date object
          const startingTimeSplit = visitingTime.startingTime.split(":");
          const startingTime = new Date();
          startingTime.setHours(parseInt(startingTimeSplit[0]));
          startingTime.setMinutes(parseInt(startingTimeSplit[1]));

          const endingTimeSplit = visitingTime.endingTime.split(":");
          const endingTime = new Date();
          endingTime.setHours(parseInt(endingTimeSplit[0]));
          endingTime.setMinutes(parseInt(endingTimeSplit[1]));

          return <Box key={index}
                      style={{
                        backgroundColor: "#f2f2f2",
                        padding: "5px 10px",
                        margin: "5px 0 2px"
                      }}
          >
            <Authorize allow={"Asiakaspalvelu"}>
              {visitingTimesWatch.length > 1 ?
              <Button onClick={() => handleVisitingTimeRemove(index)}
                      style={{float: "right"}}>
                <FontAwesomeIcon icon={faTimes}/>
              </Button>
              : '' }
            </Authorize>
            <FormControl className={classes.formControlVisitingTime}>
              <label className={classes.label}>
                <FormattedMessage id={messages.reservationVisitingTime}/>
              </label>
              <KeyboardDatePicker
                disableToolbar
                autoOk
                disabled={!isAsiakaspalvelu}
                variant="inline"
                inputVariant="filled"
                format="DD.MM.YYYY"
                margin="normal"
                className={classes.visitingTimedateField}
                value={visitingTime.visitingDate}
                onChange={handleItemDateChange(index, visitingTime.visitingDate)}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </FormControl>
            <div className={classes.divVisitingTime}>
                <KeyboardTimePicker
                  ampm={false}
                  autoOk
                  disabled={!isAsiakaspalvelu}
                  inputVariant="filled"
                  variant="inline"
                  className={classes.visitingTimedateField}
                  value={startingTime}
                  onChange={handleTimeChange('startingTime', index)}
                />
                <FontAwesomeIcon icon={ faMinus }
                                 style={{ width: "0.6em", margin: "0 8px" }}/>
                <KeyboardTimePicker
                  ampm={false}
                  autoOk
                  disabled={!isAsiakaspalvelu}
                  inputVariant="filled"
                  variant="inline"
                  className={classes.timeField}
                  value={endingTime}
                  onChange={handleTimeChange('endingTime', index)}
                />
            </div>
          </Box>
        }
      )}
      <Authorize allow={"Asiakaspalvelu"}>
        <div style={{display: "flex", justifyContent: "flex-end"}}>
          <Button onClick={handleVisitingTimeAdd}
                  variant="contained"
                  className={classes.greenButton}>
            <FormattedMessage id={messages.addNewVisitingTime}/>
          </Button>
        </div>
      </Authorize>
      <FormControl className={classes.formControl}>
        <label className={classes.label}>
          <FormattedMessage id={messages.reservationSize}/>
           *
        </label>
        <TextField
          id="reservationSize"
          name="reservationSize"
          disabled={!useAuthorize({ allow: "Asiakaspalvelu" })}
          value={sizeWatch || ""}
          inputProps={{
            name: 'reservationSize',
            id: 'reservationSize'
          }}
          onChange={handleSizeChange}
          error={errors.reservationSize}
          type="number"
          className={classes.numberField}
          variant="filled"
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <Button onClick={() => setShow(!show)}
                style={{padding: 0}}>
          <FontAwesomeIcon icon={show ? faMinus : faPlus}
                           style={{marginRight: 10}}/>
          <FormattedMessage id={messages.groupDivision}/>
        </Button>
      </FormControl>
      {show ? groupsWatch.map((group, index) =>
          <Box style={{backgroundColor: "#f2f2f2", padding: "5px 10px", margin: "5px 0 2px"}}
               key={index}>
            <Authorize allow={"Asiakaspalvelu"}>
              {groupsWatch.length > 1 ?
                <Button onClick={() => handleGroupRemove(index)}
                        style={{float: "right"}}>
                  <FontAwesomeIcon icon={faTimes}/>
                </Button>
                : ''
              }
            </Authorize>
            <FormControl className={classes.formControl}>
              <label className={classes.childFieldLabel}>
                <FormattedMessage id={messages.groupSize}/>
              </label>
              <TextField id="groupSize"
                         name="groupSize"
                         type="number"
                         disabled={!isAsiakaspalvelu}
                         value={group.groupSize}
                         className={classes.numberField}
                         onChange={(event) => handleGroupSizeChange(group, event, index)}
                         variant="filled"/>
            </FormControl>
            <FormControl className={classes.formControlColorPicker}>
              <label className={classes.childFieldLabel}>
                <FormattedMessage id={messages.groupColorHex}/>
              </label>
              <span style={{
                height: 30,
                width: 10,
                background: groupsWatch[index].groupColorHex}} />
              <ColorPicker
                name='color'
                autoOk
                defaultValue='#000'
                disabled={!isAsiakaspalvelu}
                value={group.groupColorHex}
                onChange={(color) => handleColorChange(group, color, index)}
              />
            </FormControl>

            <FormControl className={classes.formControlMultiline}>
              <label className={classes.childFieldLabel}>
                <FormattedMessage id={messages.language}/>
              </label>
              <Box style={{flexWrap: "wrap"}}>
                {languageList.map((language, langIndex) => {
                  return <Box key={langIndex} style={{
                    display: "inline-flex",
                    alignItems: "center",
                    minWidth: 110,
                    justifyContent: "space-between"
                  }}>
                    <FormattedMessage id={messages[camelCase(language)]}/>
                    <Checkbox id={language}
                              value={language}
                              key={langIndex}
                              disabled={!isAsiakaspalvelu}
                              defaultChecked={group.languages
                                ? group.languages.includes(language)
                                : false}
                              onChange={() => handleGroupCheckChange(group, language, index)}/>
                  </Box>
                })}
              </Box>
            </FormControl>
          </Box>)
        : ''
      }
      <Authorize allow={"Asiakaspalvelu"}>
        {show ?
          <div style={{display: "flex", justifyContent: "flex-end"}}>
            <Button onClick={handleGroupAdd}
                    variant="contained"
                    className={classes.greenButton}>
              <FormattedMessage id={messages.addNewGroup}/>
            </Button>
          </div>
          : ''
        }
      </Authorize>

        <FormControl className={classes.formControl}>
          <label className={classes.label}>
            <FormattedMessage id={messages.reservationType}/>
            *
          </label>
          <Select id="reservationType"
                  name="reservationType"
                  value={typeWatch || ""}
                  disabled={!useAuthorize({ allow: "Asiakaspalvelu" })}
                  onChange={handleSelectChange}
                  error={errors.reservationType}
                  className={classes.select}
                  variant="filled"
                  inputProps={{
                    name: 'reservationType',
                    id: 'reservationType'
                  }}>
            {reservationTypes.map( (type, index) =>
              <MenuItem key={index}
                        value={type}>
                <FormattedMessage id={messages[camelCase(type)]} />
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </Box>
    )
}

