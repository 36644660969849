import React, {useCallback, useEffect} from 'react';
import {FormattedMessage} from 'react-intl';
import messages from 'intl/messages.properties';
import {isEmpty} from 'lodash';
import useForm, {FormContext} from "react-hook-form";

import formStyle from '../../../styles/FormStyle';
import CustomSnackbar from "../../../shared/CustomSnackbar";
import FormActionButtons from '../../../shared/FormActionButtons';
import TabPanel from "../../../shared/TabPanel";
import ReservationContacts from './FormSections/ReservationContacts';
import BasicInfo from './FormSections/BasicInfo';
import AdditionalInfo from './FormSections/AdditionalInfo';
import SendingInfo from './FormSections/SendingInfo';
import Report from './FormSections/Report';

import {makeStyles} from '@material-ui/core/styles';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import {
  Paper,
  AppBar,
  Tabs,
  Tab
} from '@material-ui/core';

import {Alert} from "react-bootstrap";
import Program from "./FormSections/Program";
import EditOfferBase from "./FormSections/SendingInfoActions/EditOfferBase";
import Moment from "moment";
import ProgramItemEditor from "./FormSections/ProgramItemEditor";
import useAxios from "use-axios/cjs";
import {extendMoment} from "moment-range";
import EventColors, { StatusOK, StatusNotOK } from "../../../shared/definitions/EventColors";
import "moment/locale/fi";
import axios from "axios";

const useStyles = makeStyles(formStyle);
const isAccepted = item => item.status === "ACCEPTED";
const productUrl = `${process.env.API_PATH}/product/withReservations`;
const resourceUrl = `${process.env.API_PATH}/resource/withReservations`;
Moment.locale("fi");
const moment = extendMoment(Moment);

function Form(props) {
  const classes = useStyles();

  const initialData = {
    groupName: '',
    mypa: "",
    billing: "",
    ebilling: "",
    restaurantInfo: "",
    customerDocumentation: [],
    reservationVisitingTimes: [{
      visitingDate: moment().format("YYYY-MM-DD"),
      startingTime: "08:00",
      endingTime: "20:00"
    }],
    reservationGroups: [
      {
        tempId: Date.now(),
        groupColorHex: "#3e3e3d",
        groupSize: "0",
        languages: [],
        reservationGroupProgramItems: []
      }
    ],
    reservationName: "",
    reservationSize: "",
    reservationType: null,
    reservationContacts: [
      {
        id: null,
        pipedriveId: null,
        organization: "",
        firstName: "",
        lastName: "",
        jobTitle: "",
        phoneNumbers: [
          ""
        ],
        email: "",
        address: "",
        postalNumber: "",
        postOffice: "",
        reservationCategory: "VARAAJA",
        reservationRole: ""
      }
    ],
    offers: [],
    sentOffers: [],
    actualisedGroupSize: '',
    payingVisitors: '',
    freeTickets: '',
    actualisedPrice: '',
    reportFromVisitation: '',
    reactionToReservation: moment().format("YYYY-MM-DD"),
    accountableResources: []
  };

  const {data: reservationData = initialData, created = false} = props;

  const { data: productData } = useAxios(productUrl + "?dates=" + reservationData.reservationVisitingTimes.map( vT => vT.visitingDate).join(","));
  const { data: resourceData } = useAxios(resourceUrl + "?dates=" + reservationData.reservationVisitingTimes.map( vT => vT.visitingDate).join(","));

  const formMethods = useForm({
    mode: 'onBlur',
    defaultValues: reservationData
  });

  const {register, errors, handleSubmit, setValue, watch, reset} = formMethods;

  useEffect(() => {
    register({ name: "id" });
    register({ name: "reservationName" })
    register({ name: "pipedriveId" });
  }, [register]);

  const reservationGroupsWatch = watch("reservationGroups");
  const idWatch = watch("id");
  const offersWatch = watch("offers");
  const reservationVisitingTimesWatch = watch("reservationVisitingTimes");
  const reservationContactsWatch = watch("reservationContacts");
  const reservationSizeWatch = watch("reservationSize");

  const [disabled, setDisabled] = React.useState(false);
  const [success, setSuccess] = React.useState(created);
  const [error, setError] = React.useState(false);
  const [copySuccess, setCopySuccess] = React.useState(false);
  const [contactErrors, setContactErrors] = React.useState({});
  const [groupErrors, setGroupErrors] = React.useState({});
  const [groupSizeError, setGroupSizeError] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(0);
  const [showEditor, setShowEditor] = React.useState(false);
  const [offerValue, setOfferValue] = React.useState('');
  const [programItemAddOpen, setProgramItemAddOpen] = React.useState(false);
  const [editedProgramItem, setEditedProgramItem] = React.useState();
  const [programItems, setProgramItems] = React.useState([]);
  const [resources, setResources] = React.useState(resourceData);
  const [products, setProducts] = React.useState(productData);
  const [filteredProductData, setFilteredProductData] = React.useState(products);
  const [selectedDate, setSelectedDate] = React.useState(reservationData.reservationVisitingTimes[0].visitingDate || new Date());

  useEffect(() => {
    setProgramItemAddOpen(false)
  }, [selectedDate]);

  const refetchResources = useCallback(async () => {
    const { data } = await axios.get(resourceUrl + "?dates=" + reservationVisitingTimesWatch.map(vT => vT.visitingDate).join(","));
    return data;
  }, [reservationVisitingTimesWatch]);

  useEffect(() => {
    refetchResources().then((resources) => {
      setResources(resources)
    });
  }, [reservationVisitingTimesWatch, refetchResources]);

  const refetchProducts = useCallback(async () => {
    const { data } = await axios.get(productUrl + "?dates=" + reservationVisitingTimesWatch.map(vT => vT.visitingDate).join(","));
    return data;
  }, [reservationVisitingTimesWatch]);

  useEffect(() => {
    refetchProducts().then((products) => {
      setProducts(products)
    });
  }, [reservationVisitingTimesWatch, refetchProducts]);

  useEffect(() => {

    let newProductData = Object.assign([], products);
    newProductData = newProductData.map((product) => {
      const newProduct = Object.assign({}, product);
      newProduct.programItems = newProduct.programItems.filter((item) => reservationVisitingTimesWatch.some(vTime => vTime.visitingDate === item.itemDate));
      return newProduct
    });
    setFilteredProductData(newProductData);
  }, [products, reservationVisitingTimesWatch]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const closeSnackbar = () => {
    setSuccess(false);
    setError(false);
  };

  const onSubmit = async (reservationData, event) => {
    setProgramItemAddOpen(false);
    setDisabled(true);
    event.preventDefault();
    const contactValidationResult = validateContacts();
    const programItemValidationResult = validateProgramItems();
    const groupSizeValidationResult = validateGroupSize();
    if (Object.entries(errors).length > 0 || Object.entries(contactValidationResult).length > 0 || Object.entries(programItemValidationResult).length > 0 || !groupSizeValidationResult) {
      setDisabled(false);
      return;
    }

    let response = await props.save(reservationData)
    setCopySuccess(false)
    console.log({response})
    if (response && response.status >= 200 && response.status < 300) {
      setDisabled(false);
      setSuccess(true);
      setError(false);
      setValue("offers", response.data.offers);
      setValue("reservationGroups", response.data.reservationGroups);
    } else {
      const msg = response.response.data.match(/.*default message \[(.*)\]\].*/)[1];
      setDisabled(false);
      setError(msg);
      setSuccess(false);
    }
  };

  const validateGroupSize = () => {
    if (reservationGroupsWatch.map(group => parseInt(group.groupSize)).reduce((prev, next) => prev + next) === parseInt(reservationSizeWatch)) {
      setGroupSizeError(false);
      return true;
    } else {
      setGroupSizeError(true);
      return false;
    }
  };

  const validateProgramItems = () => {
    const newGroupErrors = {};
    reservationGroupsWatch.forEach((group, index) => {
      if (group.reservationGroupProgramItems.some((programItem) => {
        const product = filteredProductData.find(p => p.id === programItem.productId);
        return product.limited && product.maxGroupSize && product.maxGroupSize < group.groupSize && checkProductLimitation(programItem.productId, programItem.tempId || programItem.id, programItem.itemDate, programItem.startingTime, moment(programItem.startingTime).add("minutes", programItem.length).format("HH:mm"));
      })) {
        newGroupErrors[index] = "groupTooLarge"
      }
    });
    setGroupErrors(newGroupErrors);
    return newGroupErrors;
  };

  const validateContacts = () => {
    const newErrors = {};
    reservationContactsWatch.forEach((contact, index) => {
      const contactError = [];
      if (contact.pipedriveId) {
        return;
      }
      if (!contact.firstName || contact.firstName === "") {
        contactError.push("firstName");
      }
      if (!contact.lastName || contact.lastName === "") {
        contactError.push("lastName");
      }
      if (!contact.organization || contact.organization === "") {
        contactError.push("organization");
      }
      if (!contact.email || contact.email === "") {
        contactError.push("email");
      }
      if (contactError.length > 0) {
        newErrors[index] = contactError;
      }
    });
    setContactErrors(newErrors);
    return newErrors;
  }

  const checkProductLimitation = useCallback((productId, id, itemDate, startingTime, endingTime) => {
    const reserved = [];
    const product = filteredProductData.find((p) => p.id === productId);

    if (!product.limited) {
      return true;
    }

    const productRange = moment.range(moment(startingTime, "HH:mm"), moment(endingTime, "HH:mm"));

    programItems.forEach((pItem) => {
      const pItemDate = moment(pItem.start).format("YYYY-MM-DD");
      const pItemStart = moment(pItem.start).format("HH:mm");
      if (pItemDate !== itemDate || pItem.id === parseInt(id) || pItem.productId !== productId) {
        return;
      }
      const pItemRange = moment.range(moment(pItemStart, "HH:mm"), moment(pItemStart, "HH:mm").add(pItem.length, "minutes"));
      if (pItemRange.overlaps(productRange, { adjacent: false })) {
        reserved.push(pItem.tempId || pItem.id);
      }
    });
    product.programItems.forEach((pItem) => {
      if (pItem.itemDate !== itemDate || pItem.id === id || programItems.some(i => i.id === pItem.id)) {
        return;
      }
      const pItemRange = moment.range(moment(pItem.startingTime, "HH:mm"), moment(pItem.startingTime, "HH:mm").add(pItem.length, "minutes"));
      if (pItemRange.overlaps(productRange, { adjacent: false })) {
        reserved.push(pItem.tempId || pItem.id);
      }
    });
    return !product.maxReservations || product.maxReservations >= reserved.length + 1;
  }, [filteredProductData, programItems]);


  const saveProgramItem = (id, tempId, productId, productDescription, showProduct, itemDate, length, groupIndex, productPrice, productPricingInformation, startingTime, reservedResources, showResources) => {

    if (!showResources) {
      reservedResources = []
    }

    const newReservationGroups = [...reservationGroupsWatch];
    if (id || tempId) {
      for (let i = 0; i < reservationGroupsWatch.length; i++) {
        for (let j = 0; j < reservationGroupsWatch[i].reservationGroupProgramItems.length; j++) {
          if (reservationGroupsWatch[i].reservationGroupProgramItems[j].id === parseInt(id)
            || reservationGroupsWatch[i].reservationGroupProgramItems[j].tempId === parseInt(tempId)) {
            newReservationGroups[i].reservationGroupProgramItems[j] = {
              id: parseInt(id),
              tempId: parseInt(tempId),
              productId: productId,
              itemDate,
              startingTime,
              productPrice,
              productPricingInformation,
              length,
              productDescription,
              showProduct,
              reservedResources: reservedResources,
              resourcesRequired: showResources
            }
          }
        }
      }
    } else {
      newReservationGroups[groupIndex].reservationGroupProgramItems.push({
        tempId: Date.now(),
        productId: productId,
        itemDate: selectedDate,
        startingTime,
        productPrice,
        productPricingInformation,
        length,
        productDescription,
        showProduct,
        reservedResources: reservedResources,
        resourcesRequired: showResources
      });
    }
    setValue("reservationGroups", newReservationGroups);
    createProgramItems(showResources);
  };

  const removeProgramItem = (id, tempId) => {
    const newReservationGroups = [...reservationGroupsWatch];
    for (let i = 0; i < reservationGroupsWatch.length; i++) {
      for (let j = 0; j < reservationGroupsWatch[i].reservationGroupProgramItems.length; j++) {
        if (reservationGroupsWatch[i].reservationGroupProgramItems[j].id === parseInt(id)
          || reservationGroupsWatch[i].reservationGroupProgramItems[j].tempId === parseInt(tempId)) {
          const productId = newReservationGroups[i].reservationGroupProgramItems[j].productId;
          newReservationGroups[i].reservationGroupProgramItems.splice(j, 1);
          const product = filteredProductData.find(p => p.id === productId);
          if (product === null) {
            return;
          }
          const productItemIndex = product.programItems.findIndex(pI => pI.id === parseInt(id));
          if (productItemIndex > -1) {
            product.programItems.splice(productItemIndex, 1);
          }
        }
      }
    }
    setValue("reservationGroups", newReservationGroups);
    createProgramItems();
  };

  const openAddNewProgramItem = () => {
    setEditedProgramItem(undefined);
    setProgramItemAddOpen(true);
  };

  const editProgramItem = useCallback((programItem) => {
    setEditedProgramItem(programItem);
    setProgramItemAddOpen(true);
  }, []);

  const createProgramItems = useCallback((showResources) => {
    const newProgramItems = [];
    reservationGroupsWatch.forEach((group) => {
      group.reservationGroupProgramItems.forEach((programItem) => {
        const product = filteredProductData.find((prod => prod.id === programItem.productId));

        const constrainedResources = reservationGroupsWatch.filter((group) => {
            return product.maxGroupSize >= group.groupSize;
          }).map((group) => group.id ? group.id.toString() : group.tempId.toString());

        const startingTimeSplit = programItem.startingTime.split(":");
        const tempDate = moment(programItem.itemDate, "YYYY-MM-DD");
        tempDate.hours(parseInt(startingTimeSplit[0]));
        tempDate.minutes(parseInt(startingTimeSplit[1]));

        const resources = programItem.reservedResources.map(item => {
          const resourceId = item.resource;
          const resourceDataItem = resourceData.find(item => resourceId === item.id);
          const fullName = resourceDataItem?.fullName ?? '';
          const initials = fullName
            .split(' ')
            .map(part => part.charAt(0))
            .join('');

          return { initials };
        });

        const initials = resources.map(resource => resource.initials).join(', ');

        const productDataItem = filteredProductData.find(item => programItem.productId === item.id);
        const productBorderColor = EventColors[productDataItem.productType];

        const productStatus = programItem.resourcesRequired
          ? programItem.reservedResources.every(isAccepted) && programItem.reservedResources.length !== 0
            ? StatusOK
            : StatusNotOK
          : StatusOK;

        const title = programItem.length >= 60
          ? product.name + "\n" + initials
          : product.name + " - " + initials;

        const requiredRes = showResources
          ? showResources
          : programItem.resourcesRequired
            ? programItem.resourcesRequired
            : false;

        if (!product) {
          return;
        }
        newProgramItems.push({
          id: programItem.id,
          tempId: programItem.tempId,
          productId: product.id,
          resourceId: group.id || group.tempId,
          title: title,
          resourcesRequired: requiredRes,
          color: productStatus,
          borderColor: productBorderColor,
          productDescription: programItem.productDescription,
          start: tempDate.toDate(),
          end: tempDate.add(programItem.length, 'minutes').toDate(),
          length: programItem.length,
          productPrice: programItem.productPrice,
          productPricingInformation: programItem.productPricingInformation,
          showProduct: programItem.showProduct,
          reservedResources: programItem.reservedResources,
          constraint: {
            resourceIds: constrainedResources
          }
        });
      });
    });
    setProgramItems(newProgramItems);
  }, [filteredProductData, reservationGroupsWatch, resourceData]);


  const removeProgramItemsByDate = useCallback((date) => {
    const newReservationGroups = [...reservationGroupsWatch];
    newReservationGroups.forEach(group => {
      group.reservationGroupProgramItems = group.reservationGroupProgramItems.filter(item => item.itemDate !== date.visitingDate);
    });
    setValue("reservationGroups", newReservationGroups);
    createProgramItems();
    return true;
  }, [createProgramItems, reservationGroupsWatch, setValue]);

  const moveProgramItemsToDate = useCallback((oldDate, newDate) => {
    const newReservationGroups = [...reservationGroupsWatch];
    const newProductData = [...productData];
    setFilteredProductData(newProductData);

    for (let i = 0; i < newReservationGroups.length; i++) {
      for (let j = 0; j < newReservationGroups[i].reservationGroupProgramItems.length; j++) {
        if (newReservationGroups[i].reservationGroupProgramItems[j].itemDate !== oldDate) {
          continue;
        }
        const item = Object.assign({}, newReservationGroups[i].reservationGroupProgramItems[j]);
        if (!checkProductLimitation(item.productId, item.tempId || item.id, newDate, item.startingTime, moment(item.startingTime).add(item.length, "minutes").format("HH:mm"))) {
          return false;
        }
        newReservationGroups[i].reservationGroupProgramItems[j].itemDate = newDate;
      }
    }
    setValue("reservationGroups", newReservationGroups);
    createProgramItems();
    return true;
  }, [checkProductLimitation, createProgramItems, productData, reservationGroupsWatch, setValue]);

  const handleSaveOfferBase = (offerValue, content) => {
    offerValue.offerContent = content;

    setValue("offers", offersWatch.map( offer => {
      return offer === offerValue
        ? offerValue
        : offer
    }));
    setShowEditor(false)
  };


  useEffect(() => {
    createProgramItems();
  }, [createProgramItems]);

  useEffect(() => {

    programItems.forEach((pI) => {
      pI.tempId && pI.reservedResources.forEach((rr) => {
        const resource = resources.find(r => r.id === rr.resource);
        const startMoment = moment(pI.start);

        resource.programItems.push({
          id: pI.id,
          tempId: pI.tempId,
          itemDate: startMoment.format("YYYY-MM-DD"),
          length: pI.length,
          productDescription: pI.productDescription,
          showProduct: pI.showProduct,
          productId: pI.productId,
          productPrice: pI.productPrice,
          reservedResources: pI.reservedResources,
          resourcesRequired: pI.resourcesRequired,
          startingTime: startMoment.format("HH:mm")
        });

      })
    })

    setResources(resources)
  }, [resources, programItems]);


  return (
  <>
    <FormContext {...formMethods}>
      <MuiPickersUtilsProvider locale={"fi"} utils={MomentUtils}>
        <form
          className={classes.form}
          onSubmit={handleSubmit(onSubmit)}
        >
          {!showEditor ?
          <>
          <CustomSnackbar
            open={success}
            onClose={closeSnackbar}
            type="success"
            message={<FormattedMessage id={messages.saveSuccess}/>}
          />
          <CustomSnackbar
            open={error}
            onClose={closeSnackbar}
            type="error"
            message={typeof error === 'string' || error instanceof String ? error : <FormattedMessage id={messages.saveError}/>}
          />
            <CustomSnackbar
              open={copySuccess}
              onClose={closeSnackbar}
              type="success"
              message={<FormattedMessage id={messages.copySuccess}/>}
            />

          <FormActionButtons allow="Asiakaspalvelu"
                             disabled={disabled}
                             values={reservationData}
                             classes={classes}
                             remove={props.remove}
                             copy={props.copy}
                             reset={reset}
                             setCopySuccess={setCopySuccess}
          />

            {!isEmpty(errors) && Object.values(errors).map((error, index) => {
                return <Alert variant="danger">
                  <FormattedMessage id={error.message}/>
                </Alert>
              }
            )}
            {!isEmpty(contactErrors) &&
            <Alert variant="danger">
              <FormattedMessage id={messages.invalidContacts}/>
            </Alert>
            }
            {!isEmpty(groupErrors) && Object.entries(groupErrors).map((error, index) => {
              return <Alert variant="danger">
                <FormattedMessage id={messages.groupNumberTooLarge} values={{group: parseInt(error[0]) + 1}}/>
              </Alert>
            })}
            {groupSizeError &&
              <Alert variant="danger">
                <FormattedMessage id={messages.groupSizeMismatch} />
              </Alert>}

            <div className={classes.root}>
              <div className={classes.reservationContainer}>

                <Paper className={classes.paperSplit50}>
                  <div style={{display: !programItemAddOpen ? "flex" : "none"}} className={classes.reservationContent}>
                      <BasicInfo
                        classes={classes}
                        moveProgramItemsToDate={moveProgramItemsToDate}
                        removeProgramItemsByDate={removeProgramItemsByDate}
                        reservationData={reservationData}
                      />
                      <ReservationContacts errors={contactErrors}
                                           validateContacts={validateContacts}
                                           classes={classes}
                      />
                  </div>
                  { programItemAddOpen && <div className={classes.reservationContent}>
                    <ProgramItemEditor classes={classes}
                                       onClose={() => {
                                           setProgramItemAddOpen(false);
                                           setEditedProgramItem(undefined);
                                         }}
                                       productData={filteredProductData.filter(p => p.status === "ACTIVE")}
                                       saveProgramItem={saveProgramItem}
                                       removeProgramItem={removeProgramItem}
                                       checkProductLimitation={checkProductLimitation}
                                       reservationGroups={reservationGroupsWatch}
                                       resources={resources}
                                       programItem={editedProgramItem}
                                       selectedDate={selectedDate}
                    />
                    </div>}
                </Paper>

                <Paper className={classes.paperSplit50}>
                  <AppBar position="static"
                          className={classes.appBarReservations}>
                    <Tabs value={activeTab}
                          onChange={handleTabChange}
                          aria-label="">
                      <Tab label={<FormattedMessage id={messages.program}/>}
                           className={classes.appBarTab}
                           {...a11yProps(0)} />
                      <Tab label={<FormattedMessage id={messages.sendingInfo}/>}
                           disabled={idWatch === undefined}
                           {...a11yProps(1)} />
                      <Tab label={<FormattedMessage id={messages.additionalInfo}/>}
                           {...a11yProps(2)} />
                      <Tab label={<FormattedMessage id={messages.report}/>}
                           {...a11yProps(3)} />>
                    </Tabs>
                  </AppBar>
                  <TabPanel value={activeTab}
                            index={0}
                            className={classes.tabPanel}>
                    <Program openAddNewProgramItem={openAddNewProgramItem}
                             checkProductLimitation={checkProductLimitation}
                             selectedDate={selectedDate}
                             programItems={programItems}
                             setSelectedDate={setSelectedDate}
                             editProgramItem={editProgramItem}
                             style={{height: "100%"}}
                             formClasses={classes}
                             />
                  </TabPanel>
                  <TabPanel value={activeTab}
                            index={1}
                            className={classes.tabPanel}>
                    <SendingInfo classes={classes}
                                 setShowEditor={setShowEditor}
                                 offerValue={offerValue}
                                 setOfferValue={setOfferValue}/>
                  </TabPanel>
                  <TabPanel value={activeTab}
                            index={2}
                            className={classes.tabPanel}>
                    <AdditionalInfo classes={classes}/>
                  </TabPanel>
                  <TabPanel value={activeTab}
                            index={3}
                            className={classes.tabPanel}>
                    <Report classes={classes}/>
                  </TabPanel>
                </Paper>
              </div>
              <FormActionButtons allow="Asiakaspalvelu"
                                 disabled={disabled}
                                 values={reservationData}
                                 classes={classes}
                                 remove={props.remove}
                                 copy={props.copy}/>
            </div>
            </>
            : <EditOfferBase offerValue={offerValue}
                             setShowEditor={setShowEditor}
                             setOfferValue={setOfferValue}
                             handleSave={handleSaveOfferBase}/>}
        </form>
      </MuiPickersUtilsProvider>
    </FormContext>
   </>
  );
}

export default Form;
