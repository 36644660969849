import React, {useEffect} from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import messages from 'intl/messages.properties';
import useAxios, {reload} from "use-axios/cjs";
import { camelCase } from "lodash";

import MaterialTable from 'material-table';
import languageList from '../../../shared/definitions/languageList';
import EnhancedTableHead from '../../../shared/EnhancedTableHead';
import tableIcons from '../../../shared/definitions/tableIcons';

import { useFormContext } from 'react-hook-form'

import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogActions
} from '@material-ui/core';

import qualificationsDialogStyle from '../../../styles/QualificationsDialogStyle';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus } from "@fortawesome/free-solid-svg-icons/index";
import Authorize from "@postinumero/authorization/Authorize";
import useAuthorize from "@postinumero/authorization/useAuthorize";

const url = `${process.env.API_PATH}/product`;
const useStyles = makeStyles(qualificationsDialogStyle);

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
  { id: 'name', numeric: false, label: 'Tuote' },
  languageList.map( language => {
    return language === "FI_SIMPLIFIED" || language === "OTHER"  ?
      { id: language, numeric: false, label: <FormattedMessage id={messages[camelCase(language)]}/>}
        : { id: language, numeric: false, label: language}
    }
  ),
  { id: 'actions', numeric: false, label: ''},
];

export default function Qualifications(props) {
  const { onClose, open, selectedValues } = props;

  const { setValue } = useFormContext();

  const classes = useStyles();

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected] = React.useState([]);
  const [oldQualificationValues] = [selectedValues];

  const { data } = useAxios(url);
  useEffect(() => {
    const interval = setInterval(() => reload(url), 30000);
    return () => clearInterval(interval);
  }, []);

  const qualificationIds = selectedValues.map( value => value.productId );

  const rowData = Object.values(data).filter( product =>
    !qualificationIds.includes(product.id));

  function handleCheckChange(language, row) {
    setValue("productQualifications", selectedValues.map(qualification => {
      if (qualification.productId === row.productId) {
        let { languages = [] } = qualification;
        if (languages.includes(language)) {
          languages = languages.filter(qualificationLanguage =>
            qualificationLanguage !== language);
        } else {
          languages = [...languages, language];
        }
        return { ...qualification, languages };
      } else {
        return qualification;
      }
    }));
  }

  function handleListItemClick(value) {
    setValue("productQualifications", [...selectedValues, {
      productId: value.id,
      productName: value.name,
      languages: []
    }]);
  }

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleClose() {
    let checkedProducts = selectedValues.filter( product => product.languages)
      .filter( product => product.languages.length > 0);

    setValue("productQualifications", checkedProducts);

    onClose(checkedProducts);
  }

  function removeProduct(rowId) {
    let newQualifications = selectedValues.filter(product => product.productId !== rowId);

    setValue("productQualifications", newQualifications);
  }

  return (
    <Dialog onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
            maxWidth = 'xl'
            fullWidth={true}
    >
      <DialogTitle id="simple-dialog-title">
        <FormattedMessage id={messages.productDialogTitle}/>
      </DialogTitle>
      <fieldset disabled={!useAuthorize({ allow: "Myyntipalvelu" })}>
      <div className={classes.dialogContent}>
        <div className={classes.split40}>

          <MaterialTable
            title={<FormattedMessage id={messages.products} />}
            columns={[{title: <FormattedMessage id={messages.product}/> , field: "name"}]}
            data={rowData}
            icons={tableIcons}
            localization={{
              header: {
                actions: <FormattedMessage id={messages.actions} />
              },
              body: {
                emptyDataSourceMessage : <FormattedMessage id={messages.emptyDataSourceMessage} />
              },
              toolbar: {
                searchTooltip : <FormattedMessage id={messages.search} />,
                searchPlaceholder: 'Haku'
            }
            }}
            options={
              { paging: false,
                actionsColumnIndex: -1 }
            }
            actions={[{
              icon: <FontAwesomeIcon icon={faPlus} />,
              tooltip: 'Add product',
              onClick: (event, rowData) => {
                // add
              }
            }]}
            components={{
              Action: props => (<>
                  <Button onClick={ (event) => handleListItemClick(props.data, event)}
                          value={props.data.productId}>
                    <FormattedMessage id={messages.addProduct} />
                  </Button>
                </>
              )
            }}
          />
        </div>

        <div className={classes.split60}>

          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              headRows={ headRows }
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={selectedValues.length}
            />
            <TableBody className={classes.tableBody}>
              { stableSort(selectedValues, getSorting(order, orderBy)).map((row, index) => {
                let product = Object.values(data).filter( product => product.id === row.productId )[0];
                if (!product) {
                  return null;
                }
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={index}
                  >
                    <TableCell>
                      {product.name}
                    </TableCell>
                    { languageList.map( (language, index) => {
                      return <TableCell key={index}>
                        <Checkbox id={language}
                                  value={language}
                                  key={index}
                                  onChange={() => handleCheckChange(language, row)}
                                  defaultChecked={row.languages ? row.languages.includes(language) : false}
                        />
                      </TableCell>
                      }
                    )}
                    <TableCell>
                      <Button className={classes.removeButton}
                              onClick={() => removeProduct(row.productId)}>
                        <FontAwesomeIcon
                          icon={ faTrash }
                        />
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </div>
      </fieldset>

      <DialogActions className={classes.dialogActions}>
        <Button onClick={() => { setValue("productQualifications", oldQualificationValues); onClose(oldQualificationValues) } }
                color="default"
                variant="contained"
                className={classes.actionButton}>
          <FormattedMessage id={messages.cancel}/>
        </Button>
        <Authorize allow={"Myyntipalvelu"}>
          <Button onClick={() => handleClose()}
                  color="primary"
                  variant="contained"
                  className={classes.greenButton}>
            <FontAwesomeIcon icon={faPlus}
                             style={{marginRight: 10}}/>
            <FormattedMessage id={messages.add}/>
          </Button>
        </Authorize>
      </DialogActions>
    </Dialog>
  )
}

Qualifications.propTypes = {
  selectedValues: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};


