import { Suspense } from 'react';
import Spinner from 'Spinner';
import Form from './Form';
import useAxios from "use-axios";
import {delete as del, post, put} from 'axios';


function EditReservation(props) {
  const reservationUrl = `${process.env.API_PATH}/reservation`;

  const {data} = useAxios(`${reservationUrl}/${props.match.params.id}`);

  const created = props.location.state ? props.location.state.created : false;

  const save = async (data) => {
    try {
      return await put(`${reservationUrl}`, data);
    } catch (error) {
      return error;
    }
  };

  const copy = async (data) => {
    try {
      const response = await post(`${reservationUrl}/clone/${data.id}`);
      props.history.push(`/reservation/${response.data.id}`);
      return response.data;
    } catch (error) {
      return false;
    }
  };

  const remove = async data => {
    try {
      await del(`${reservationUrl}/{id}?id=${data.id}`);
      props.history.push('/reservations');
      return true;
    } catch (error) {
      return false;
    }
  };

  return (
    <>
      <Suspense fallback={<Spinner/>}>
        <Form data={data}
              save={save}
              created={created}
              remove={remove}
              copy={copy}
        />
      </Suspense>
    </>
  );
}

export default EditReservation;
