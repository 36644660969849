import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'intl/messages.properties';
import { camelCase } from 'lodash';
import useAxios, { reload } from 'use-axios';
import { useHistory } from "react-router-dom";

import listStyle from '../../../styles/ListStyle';
import EnhancedTableHead from '../../../shared/EnhancedTableHead';
import Search from '../../../shared/Search';

import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TablePagination
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from "@fortawesome/free-solid-svg-icons/index";
import product from "../Product/path";

const url = `${process.env.API_PATH}/product`;

const iconsArray = Object.entries(icons).map( icon => {
  return  icon.iconName !== "fas" ? icon : ''
});

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
  { id: 'name', numeric: false, label: 'Tuote' },
  { id: 'length', numeric: true, label: 'Kesto' },
  { id: 'status', numeric: false, label: 'Tila' },
  { id: 'productIcon', numeric: false, label: 'Tunnus' },
];


const useStyles = makeStyles(listStyle);

export default function EnhancedTable() {
  const classes = useStyles();
  const history = useHistory();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchValue, setSearchValue] = React.useState('');
  const [emptyRows, setEmptyRows] = React.useState(0);

  const { data } = useAxios(url);

  const [filteredData, setFilteredData] = React.useState(data);
  useEffect(() => {
    const interval = setInterval(() => reload(url), 30000);
    return () => clearInterval(interval);
  }, []);

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  useEffect(() => {
    setPage(0);
  }, [searchValue]);

  useEffect(() => {
    setFilteredData(data.filter( product => product.name.toUpperCase().includes( searchValue.toUpperCase() ) ));
  }, [searchValue, data])

  const isSelected = name => selected.indexOf(name) !== -1;

  useEffect(() => {
    setEmptyRows(rowsPerPage - Math.min(rowsPerPage, filteredData.length - page * rowsPerPage));
  }, [rowsPerPage, filteredData, page])

  return (
    <div className={classes.root}>

      <div className={classes.searchContainer} >
        <Search searchValue={searchValue}
                setSearchValue={setSearchValue} />
      </div>

      <div className={classes.tableWrapper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
        >
          <EnhancedTableHead
            headRows={headRows}
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={filteredData.length}
          />
          <TableBody>
            {stableSort(filteredData, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.id);

                return (
                  <TableRow
                    hover
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}
                    onClick={() => history.push(product + `/`+ row.id)}
                    className="pointer"
                  >
                      <TableCell>
                        {row.name}
                      </TableCell>
                      <TableCell>
                        {row.length} min
                      </TableCell>
                      <TableCell>
                        <FormattedMessage id={messages[camelCase(row.status)]}/>
                      </TableCell>

                    <TableCell>
                      { row.productIcon
                        ? iconsArray.map(([name, icon]) => {
                          let iconClassName = row.productIcon !== undefined
                            ?`${row.productIcon.split('|')[0]}Icon`
                            : '';
                          return name === row.productIcon.split('|')[1]
                            ? <FontAwesomeIcon key={name}
                                               icon={icon}
                                               className={classes[iconClassName]}/>
                            : ''})
                        : ''
                      }

                  </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        </div>
        <TablePagination
          className={classes.pagination}
          rowsPerPageOptions={[10, 15, 20]}
          component="div"
          count={filteredData.length}
          labelRowsPerPage = {<FormattedMessage id={messages.rowsPerPage}/>}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
    </div>
  );
}
