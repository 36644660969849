import React, {useEffect, useRef, useState} from 'react';
import {render} from 'react-dom';
import { FormattedMessage } from 'react-intl';
import messages from 'intl/messages.properties';

import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';

import {
  makeStyles,
  Button, Paper
} from "@material-ui/core";
import moment from "moment/moment";

import Notes from './Notes';
import formStyle from "../../../styles/FormStyle";
import EventColors, { StatusOK, StatusNotOK } from "../../../shared/definitions/EventColors";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCalendar, faPrint} from "@fortawesome/free-solid-svg-icons/index";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import fi from "date-fns/locale/fi";
import { put } from "axios";
import {reload} from "use-axios";
import Authorize from "@postinumero/authorization/Authorize";
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

registerLocale("fi", fi);

const useStyles = makeStyles(formStyle);
const isAccepted = item => item.status === "ACCEPTED";
const DATE = "YYYY-MM-DD";
const TIME = "HH.mm";
const START_TIME = "8:00";
const SCROLL_TIME = Math.round(moment().format("HH.mmss") - 2) + ":00:00";

const toResources = ({ selectedDateString }) => (reservationDataItem, index) =>
  reservationDataItem.reservationGroups.map((group,groupIndex) => {
    const reservationStatus = group.reservationGroupProgramItems
      .filter(item => selectedDateString === item.itemDate)
      .every(item =>
        (item.reservedResources.every(isAccepted) && item.reservedResources.length !== 0) || !item.resourcesRequired
      )
      ? StatusOK
      : StatusNotOK;

    return {
      id: `${index}.${groupIndex}`,
      reservationId: reservationDataItem.id,
      reservationName: reservationDataItem.reservationName,
      title: `Ryhmä ${groupIndex + 1}\n${reservationDataItem.reservationGroups.length > 1
        ? group.groupSize
        : reservationDataItem.reservationSize
      } hlö`,
      status: reservationStatus,
      color: group.groupColorHex,
    };
  });

const toEvents = ({ productData, resourceData }) => (reservationDataItem, index) =>
  reservationDataItem.reservationGroups.flatMap((group,groupIndex) =>
    group.reservationGroupProgramItems
      .filter((groupItem) => !!productData.find(item => groupItem.productId === item.id))
      .map(groupItem => {
        const productDataItem = productData.find(item => groupItem.productId === item.id);
        const productName = productDataItem.name;
        const productBorderColor = EventColors[productDataItem.productType];

        const resources = groupItem.reservedResources.map(item => {
          const resourceId = item.resource;
          const resourceDataItem = resourceData.find(item => resourceId === item.id);
          const fullName = resourceDataItem?.fullName ?? '';
          const initials = fullName
            .split(' ')
            .map(part => part.charAt(0))
            .join('');

          return { fullName, initials };
        });

        const initials = resources.map(resource => resource.initials).join(', ');
        const fullNames = resources.map(resource => resource.fullName).join(', ');

        const productStatus = groupItem.resourcesRequired
          ? groupItem.reservedResources.every(isAccepted) && groupItem.reservedResources.length !== 0
            ? StatusOK
            : StatusNotOK
          : StatusOK;

        const eventTime = moment(groupItem.itemDate);
        const eventStart = moment(groupItem.itemDate);
        const eventBegin = moment(groupItem.itemDate);
        const startingTimeSplit = groupItem.startingTime.split(":");

        // multiple, because it kept changing values inside const calculations and return statement
        eventStart.hours(parseInt(startingTimeSplit[0]));
        eventStart.minutes(parseInt(startingTimeSplit[1]));
        eventBegin.hours(parseInt(startingTimeSplit[0]));
        eventBegin.minutes(parseInt(startingTimeSplit[1]));
        eventTime.hours(parseInt(startingTimeSplit[0]));
        eventTime.minutes(parseInt(startingTimeSplit[1]));

        const tooltip = productName + " | " + moment(eventTime).format(TIME) + " - "
          + moment(eventTime.add(groupItem.length, 'minutes')).format(TIME)
          + " | Resurssit: " + fullNames;
        return {
          resourceId: `${index}.${groupIndex}`,
          content: <TooltipContent productName={productName}
                                   initials={initials}
                                   tooltip={tooltip}
                                   startTime={moment(eventStart.toDate()).format(TIME)}
                                   endTime={moment(eventStart.add(groupItem.length, 'minutes').toDate()).format(TIME)}
                   />,
          start: eventBegin.toDate(),
          end: eventBegin.add(groupItem.length, 'minutes').toDate(),
          color: productStatus,
          borderColor: productBorderColor
        };
      }));

const toContentHeight = ({ reservationData, selectedDateString }) => {
  return reservationData.some((reservationDataItem) =>
    reservationDataItem.reservationGroups.some((group) =>
      group.reservationGroupProgramItems.some((item) =>
        selectedDateString === item.itemDate
      )))
    ? 'auto'
    : 100;
};

function CalendarFunction ({ calendarRef, classes, selectedDate, setSelectedDate }) {
  if (calendarRef.current !== undefined) {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.gotoDate(selectedDate);
  }

  return (
      <div id="front-page-calendar-button">
        <DatePicker selected={selectedDate}
                    onChange={date => setSelectedDate(date)}
                    customInput={
                      <Button className={classes.button}
                              variant={"contained"}
                              style={{ margin: "0 0 1.5em", padding: "5px 7px " }}>
                        <FontAwesomeIcon icon={faCalendar}
                                         style={{ margin: "0 10px 0 0" }}
                        />
                        <FormattedMessage id={messages.calendar} />
                      </Button>
                    }
                    todayButton="Tänään"
                    locale="fi"
                    selectOtherMonths={false}
                    popperModifiers={{
                      flip: {
                        enabled: false
                      }
                    }}
        />
      </div>
    );
}

function TooltipContent({ productName, initials, tooltip, startTime, endTime }) {

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipToggle = () => {
    setOpen(open => !open);
  };

  return <ClickAwayListener mouseEvent={"onMouseDown"} onClickAway={handleTooltipClose}>
    <Tooltip
      PopperProps={{
        disablePortal: false,
      }}
      arrow
      onClose={handleTooltipToggle}
      open={open}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={tooltip}
    >
      <div onClick={handleTooltipToggle} style={{height:"100%"}}>
        <div style={{width: 1000}}>{productName}</div>
        <div>{initials}</div>
        <div>{startTime} - {endTime} </div>
      </div>
    </Tooltip>
  </ClickAwayListener>
}

export default function TodaysProgram({ reservationData, productData, resourceData, noteData = [] }) {
  const classes = useStyles();
  const calendarRef = useRef();
  const [ showNotes, setShowNotes ] = React.useState(false);
  const [ selectedDate, setSelectedDate ] = useState(new Date());
  const selectedDateMoment = moment(selectedDate);
  const selectedDateString = selectedDateMoment.format(DATE);
  const [ activeNote, setActiveNote ] = React.useState(noteData.find(note => note.date === selectedDateString) || { date: selectedDateString, noteText: "" });

  useEffect(() => {
    setActiveNote(noteData.find(note => note.date === selectedDateString) || { date: selectedDateString, noteText: "" });
  }, [noteData, selectedDateString])

  const handleNoteSave = async note => {
    const newNote = await save(note);

    reload(`${process.env.API_PATH}/note`);
    setActiveNote(newNote);
  };


  const save = async (newNote) => {
    try {
      const resp = await put(`${process.env.API_PATH}/note`, newNote);
      return resp.data;
    } catch (error) {
      return false;
    }
  };

  const resources = reservationData.flatMap(toResources({ selectedDateString }));

  const events = reservationData.flatMap(toEvents({ productData, resourceData }));

  const contentHeight = toContentHeight({ reservationData, selectedDateString });

  const resourceColumns = [
    {
      labelText: 'Ryhmät',
      field: 'title',
      render: function( resource, el ) {
        el.style.borderTop = `solid 16px ${resource.extendedProps.color}`;
        el.style.backgroundColor = `${resource.extendedProps.status}`;
      },
    }
  ];

  return (
    <>
      <Button variant="contained"
              className={classes.button}
              id="front-page-print-button"
              style={{ margin: "0 0 1.5em", padding: "5px 7px " }}
              onClick={() => window.print()}>
        <FontAwesomeIcon icon={faPrint}
                         style={{ margin: "0 10px 0 0" }} />
        <FormattedMessage id={messages.print} />
      </Button>
      <CalendarFunction classes={classes}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        calendarRef={calendarRef}
      />
      <FullCalendar
        ref={calendarRef}
        schedulerLicenseKey={'GPL-My-Project-Is-Open-Source'}
        defaultView="resourceTimeline"
        plugins={[ resourceTimelinePlugin ]}
        locale={"fi"}
        contentHeight={contentHeight}
        datesRender={function() {
          if (calendarRef.current !== undefined) {
            const calendarApi = calendarRef.current.getApi();
            const switchedDate = calendarApi.getDate();
            if (selectedDateString !== moment(switchedDate).format(DATE)) {
              setSelectedDate(switchedDate);
            }
            calendarApi.scrollToTime(START_TIME);
            if (selectedDateString === moment().format(DATE)) {
              calendarApi.scrollToTime(SCROLL_TIME);
            }
          }
        }}
        resourceAreaWidth={"15%"}
        resources={resources}
        resourceColumns={resourceColumns}
        resourceGroupField={'reservationId'}
        resourceGroupText={function (reservationId) {
          const resource = resources.find((resource) => reservationId === resource.reservationId);
          return resource ? resource.reservationName : '';
        }}
        resourceGroupRender={function(groupValue, renderInfo) {
          groupValue.addEventListener("click", function () {
            let newWindow = window.open('','_blank');
            newWindow.location.href = `/reservation/${renderInfo}`;
          });
        }}
        displayEventTime={true}
        displayEventEnd={true}
        events={events}
        eventRender={function (info) {
          info.el.style.border = "solid 1px #b4b4b4";
          info.el.style.borderTop = `solid 17px ${info.event._def.ui.borderColor}`;
          render(info.event.extendedProps.content, info.el);
          return;
        }}
        eventBorderColor={'color'}
        eventBackgroundColor={'white'}
        eventTimeFormat={{
          hour: 'numeric',
          minute: '2-digit',
          hour12: false
        }}
        filterResourcesWithEvents={true}
        slotWidth={50}
        slotLabelFormat={{
          hour: 'numeric',
          minute: '2-digit',
          hour12: false
        }}
        slotEventOverlap={true}
        minTime={"08:00:00"}
        maxTime={"24:00:00"}
        nowIndicator={true}
        scrollTime={SCROLL_TIME}
        header={{
          left:   '',
          center: 'prev, title, next',
          right:  ''
        }}
        footer={{
          center: `${<FormattedMessage id={messages.save} />}`
        }}
      />
      <div id="notes-area">
        <Authorize allow={"Asiakaspalvelu"}>
          <Button onClick={() => setShowNotes(true)}
                  variant={"contained"}
                  className={classes.button}
                  style={{float: "none"}}
                  id="notes-button"
          >
            <FormattedMessage id={messages.notes}/>
          </Button>
        </Authorize>
        {showNotes &&
          <Notes note={activeNote}
                 handleNoteSave={handleNoteSave}
                 showNotes={showNotes}
                 setShowNotes={setShowNotes}
                 selectedDate={selectedDate}
          />
        }
        { activeNote?.noteText.length
          ?
            <Paper id="visible-notes">
              {activeNote.noteText}
            </Paper>
          : ''
        }
      </div>
    </>
    );
}
