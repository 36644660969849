import { FormattedMessage } from 'react-intl';
import { Suspense } from 'react';
import Spinner from 'Spinner';
import messages from 'intl/messages.properties';
import Page from 'Page';
import { post } from 'axios';
import React from "react";
import Form from "./Form";

// when the user wants to add a new newsletter
function AddNewsletter(props) {

  const newsletterUrl = `${process.env.API_PATH}/newsletter`;


  // saves the newly created newsletter
  const save = async (data) => {
    try {
      const response = await post(`${newsletterUrl}`, data);
      props.history.replace({
        pathname: `/newsletter/${response.data.id}`,
        state: { created: true }
      });
    } catch (error) {
      return false;
    }
  };

  return (
      <Page>
        <Page.Title>
          <FormattedMessage id={messages.newsletterAddPage}/>
        </Page.Title>
        <Suspense fallback={<Spinner />}>
          <Form save={save}/>
        </Suspense>
      </Page>
  );
}

export default AddNewsletter;
