import React from 'react';
import {FormattedMessage} from 'react-intl';
import messages from 'intl/messages.properties';

import DeleteDialog from "../../../../shared/DeleteDialog";
import SendDialog from './Dialogs/SendDialog';

import {
  Button,
} from '@material-ui/core';
import {useFormContext} from "react-hook-form";
import Authorize from "@postinumero/authorization/Authorize";
import {get} from "axios";

const offerUrl = `${process.env.API_PATH}/offers`;

export default function TableActions(props) {
  const {rowData, classes, setOfferValue, setShowEditor} = props;
  const [open, setOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const {setValue, watch} = useFormContext();

  const offersWatch = watch("offers");

  const edit = () => {
    setOfferValue(rowData);
    setShowEditor(true);
  };

  const preview = () => {
      get(`${offerUrl}/${rowData.id}/preview`, {
        responseType: "blob"
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', response.headers["content-disposition"].match("filename=([^;]*).*$")[1]);
        document.body.appendChild(link);
        link.click();
      });
  };

  const remove = () => {
    setValue("offers", offersWatch.filter(offer => offer !== rowData));
  };

  return (<>
      <Authorize allow={"Myyntipalvelu"}>
        <Button className={classes.actionButton}
                onClick={edit}>
          <FormattedMessage id={messages.edit}/>
        </Button>
      </Authorize>
      <Button disabled={rowData.id === undefined}
              className={classes.actionButton}
              onClick={preview}>
        <FormattedMessage id={messages.preview}/>
      </Button>
      <Authorize allow={"Myyntipalvelu"}>
        <Button disabled={rowData.id === undefined}
                className={classes.actionButton}
                onClick={() => setOpen(true)}>
          <FormattedMessage id={messages.send}/>
        </Button>
        <Button className={classes.actionButton}
                onClick={() => setDeleteDialogOpen(true)}>
          <FormattedMessage id={messages.remove}/>
        </Button>
      </Authorize>

      <SendDialog open={open}
                  setOpen={setOpen}
                  classes={classes}
                  rowData={rowData}/>

      <DeleteDialog open={deleteDialogOpen}
                    setOpen={setDeleteDialogOpen}
                    remove={remove}
                    classes={classes}/>
    </>
  )
}
