import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { delete as del } from 'axios';
import {FormattedMessage} from "react-intl";
import messages from 'intl/messages.properties';
import {Link} from "react-router-dom";
import {reload} from "use-axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {makeStyles} from "@material-ui/core";
import productStyle from "../../../styles/FormStyle";

const useStyles = makeStyles(productStyle);

// DeleteDialogue is used to delete the newsletter, admin only
export default function DeleteDialogue({ id }) {

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    return (
      <div>
        <Button className={classes.redButton}
                variant="contained"
                color="primary"
                onClick={handleClickOpen}
        >
          <FontAwesomeIcon icon={faTrashAlt}
                           style={{marginRight: 10}}
          />
          <FormattedMessage id={messages.newsletterDeleteButton}/>
        </Button>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <FormattedMessage id={messages.newsletterDeleteTitle}/>
          </DialogTitle>

          <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <FormattedMessage id={messages.newsletterDeleteBody}/>
              </DialogContentText>
          </DialogContent>

          {/* yes / no | confirms, if user wants to delete the newsletter forever */}
          <DialogActions>
              <Button
                onClick={handleClose}
                color="primary"
              >
                <FormattedMessage id={messages.newsletterDeleteNo}/>
              </Button>

            {/* deletes the newsletter and send user to the front page */}
            <Link to={`/`}>
                <Button autoFocus
                        onClick={async newsletterDeleter => {
                                 newsletterDeleter.persist();
                                 await del(`${process.env.API_PATH}/newsletter/${id}`);
                                 reload(`${process.env.API_PATH}/newsletter`);
                                 handleClose();
                        }}
                >
                  <FormattedMessage id={messages.newsletterDeleteYes}/>
                </Button>
              </Link>
            </DialogActions>
        </Dialog>
      </div>
    );
}
