import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core";
import invitesStyle from '../styles/InvitesStyle';
import {useEffect, useState} from "react";
import { post } from 'axios';
import Spinner from "../Pages/shared/Spinner";
import PageContainer from "../Pages/shared/Page/Page";
import {Alert} from "react-bootstrap";
import AppBarMenu from "../Pages/shared/Page/AppBarMenu";

const useStyles = makeStyles(invitesStyle);

function Invites(props) {
  const classes = useStyles();
  const answerInviteUrl = `${process.env.API_PATH}/invites/answer`

  const [waiting, setWaiting] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function answerInvite() {
      const params = new URLSearchParams(props.location.search);
      await post(`${answerInviteUrl}`,
        {
          inviteId: props.match.params.inviteId,
          answer: params.get("answer")
        }
      )
    }
    answerInvite().then(() => setWaiting(false)).catch((error) => {
      if (error.response) {
        setError("Ohjelmakutsua ei löytynyt.")
      } else if (error.request) {
        setError("Yhteysvirhe. Yritä hetken päästä uudestaan.")
      } else {
        setError("Tapahtui virhe.");
      }
      setWaiting(false);
    });
  }, [answerInviteUrl, props]);
  useEffect(() => {
    console.log("updated answerInviteUrl")
  }, [answerInviteUrl]);
  useEffect(() => {
    console.log("updated props")
  }, [props]);

  return (
    <>
        <PageContainer className={classes.pageContainer}>
          <AppBarMenu/>
          <Paper className={classes.paper}>
            { waiting ? <Spinner/> :
              !error ?
                <>
                  <Alert variant="success">
                    Vastauksen lähettäminen onnistui! Voit sulkea välilehden.
                  </Alert>
                  <Alert variant="warning" style={{marginBottom: "0"}}>
                    Muistathan lisätä kalenterikutsun myös omaan kalenteriisi tuplaklikkaamalla kalenterikutsun liitetiedostoa!
                  </Alert>
                </>
                : error}
          </Paper>
        </PageContainer>
    </>
  )
};

export default Invites
