import Keycloak from 'keycloak-js';
import { KeycloakProvider } from 'react-keycloak';
import Suspend from 'Suspend';
import HandleRefresh from "./HandleRefresh";

const keycloak = new Keycloak({
  clientId: process.env.KEYCLOAK_CLIENT_ID,
  realm: process.env.KEYCLOAK_REALM,
  url: process.env.KEYCLOAK_URL,
  //onLoad: "login-required"
});

const initConfig = {
  onLoad: "check-sso",
  promiseType: "native",
}

export default ({ children }) => {

  setInterval(() => {
    keycloak.updateToken(70).then((refreshed) => {
      if (refreshed) {
        console.debug("Token refreshed")
      }
    })/*.error(() => {
    console.error('Failed to refresh token');
  });*/
  }, 50000);

  return <KeycloakProvider keycloak={keycloak} initConfig={initConfig} LoadingComponent={<Suspend/>}>
    <HandleRefresh/>
    {children}
  </KeycloakProvider>
}


