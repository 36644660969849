import { Suspense } from 'react';
import Spinner from 'Spinner';
import Form from './Form';
import { post } from 'axios';
import React from "react";

function NewProduct(props) {
  const productUrl = `${process.env.API_PATH}/product`;

  const save = async (data) => {
    try {
      const response = await post(`${productUrl}`, data);
      props.history.replace({
        pathname: `/product/${response.data.id}`,
        state: { created: true }
      });
    } catch (error) {
      return false;
    }
  };

  return (
    <>
      <Suspense fallback={<Spinner />}>
        <Form save={save} />
      </Suspense>
    </>
  );
}

export default NewProduct;
