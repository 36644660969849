import React from 'react';
import { FormattedMessage } from 'react-intl';
import BackButton from 'BackButton';
import messages from 'intl/messages.properties';
import Page from 'Page';
import Paper from '@material-ui/core/Paper';
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import Authorize from "@postinumero/authorization/Authorize";

import useAxios, {reload} from "use-axios";
import moment from "moment";
import ReactMarkdown from "react-markdown";

// includes title, writer's name, body and date
function Info({ newsletterData }) {

  return (
    <div className="newsletter-page-div">
      <h1>
        {newsletterData.title}
      </h1>
      <h6 className="newsletter-writer">
        {newsletterData.writerName}
      </h6>
      <span>
        <ReactMarkdown source={newsletterData.bodyText} />
      </span>
      <p>
        {moment(newsletterData.createdTimestamp).format("DD.MM.YYYY")}
      </p>
    </div>
  );
}

// shows the newsletter that matches the given id
export default function Newsletter ({match}) {

  const newsletterId = match.params.id;
  const {data: newsletterData} = useAxios(`/api/newsletter/${newsletterId}`);

  // if the newsletter has been modified, loads the newsletter again
  reload(`${process.env.API_PATH}/newsletter`);

  return (
    <Page>
      <Page.Title>
        <FormattedMessage id={messages.newsletterSingle}/>
      </Page.Title>
      <Paper>
        <Info newsletterData={newsletterData}/>
      </Paper>
      <BackButton />
        <Link to={`/newsletter/${newsletterId}/edit`}>
          <Authorize allow="Asiakaspalvelu">
            <Button variant="contained"
                    className="news-button"
            >
              <FontAwesomeIcon icon={faPencilAlt}
                               style={{marginRight: 10}}/>
              <FormattedMessage id={messages.newsletterEdit} />
            </Button>
          </Authorize>
        </Link>
    </Page>
  );
}
